import React from "react"
import { graphql, Link } from "gatsby"
import Header from "../components/header/header"
import Seo from "../components/seo"
import Footer from "../components/footer/Footer"
import "./singlePost.scss"
export default function BlogPost({ data }) {
  const post = data.allWpPost.nodes[0]
  const seo = post?.seo
  const excerpt = post?.excerpt
    ? post.excerpt.replace(/(<([^>]+)>)/gi, "")
    : post?.content.replace(/(<([^>]+)>)/gi, "").substring(0, 100)
  //string.substring(0, length)
  const twTitle = seo?.twitterTitle ? seo.twitterTitle : post.title
  const twDesc = seo?.twitterDescription ? seo.twitterDescription : excerpt
  const twImage = seo?.twitterImage?.sourceUrl
    ? seo.twitterImage.sourceUrl
    : post.featuredImage?.node.sourceUrl

  const fbTitle = seo?.opengraphTitle ? seo.opengraphTitle : post.title
  const fbDesc = seo?.opengraphDescription ? seo.opengraphDescription : excerpt
  const fbImage = seo?.opengraphImage?.sourceUrl
    ? seo.opengraphImage.sourceUrl
    : post.featuredImage?.node.sourceUrl
  console.log(post.excerpt)
  return (
    <>
      <Seo
        title={post.title}
        facebookImage={fbImage}
        twitterImage={twImage}
        twitterTitle={twTitle}
        facebookTitle={fbTitle}
        description={excerpt}
        twitterDescription={twDesc.replace(/(<([^>]+)>)/gi, "")}
        facebookDescription={fbDesc.replace(/(<([^>]+)>)/gi, "")}
        ogType="article"
      />
      <div className="single-header">
        <Header></Header>
      </div>
      <div className="breadcrumbs block-section">
        <div className="container">
          <ul>
            <li>
              <Link to="/">Hjem</Link>
            </li>
            <li>
              <Link to={post.categories.nodes[0].uri}>
                {post.categories.nodes[0].name}
              </Link>
            </li>
            <li>{post.title}</li>
          </ul>
        </div>
      </div>
      <article className="single-article">
        <div className="container">
          <h1 className="text-container single-article__title">{post.title}</h1>

          {post.featuredImage?.node.sourceUrl ? (
            <div className="single-article__thumb">
              <img
                className="main-banner__image"
                src={post.featuredImage.node.sourceUrl}
                srcSet={post.featuredImage.node.srcSet}
                sizes={post.featuredImage.node.sizes}
                alt={post.featuredImage.node.altText}
              />
            </div>
          ) : (
            ""
          )}
          {post.excerpt ? (
            <div
              className="single-article__excerpt text-container"
              dangerouslySetInnerHTML={{ __html: post.excerpt }}
            />
          ) : (
            ""
          )}
          <div
            className="single-article__content text-container"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </article>
      <Footer></Footer>
    </>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
        categories {
          nodes {
            uri
            slug
            name
          }
        }
        seo {
          metaKeywords
          metaDesc
          schema {
            articleType
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          twitterImage {
            sourceUrl
          }
          opengraphImage {
            sourceUrl
          }
        }
      }
    }
  }
`
